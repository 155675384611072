import { CdsAlert } from "@cds/core/alert";
import { CdsModal } from "@cds/core/modal";
import { CdsButton } from "@cds/core/button";
import nanohtml from "nanohtml";
import isNetworkError from "is-network-error";
import { maxFileSize, places, placesType, styles } from "../../common/vars";

const cutplaceData = JSON.parse(document.body.dataset.cutplace || '{}') as ProfileData;

export type ProfileUser = {
    id: number,
    email: string,
    verified: boolean,
    admin?: true,
};
export type ProfileRenovation = {
    access: 'guest'|'free',
    remaining: number,
};
export type ProfileData = {
    user: ProfileUser,
    renovation: ProfileRenovation,
}
class ProfileUtil {

    private data: ProfileData;
    private cbs: ((data: ProfileData) => any)[] = [];

    constructor() {
        this.data = {
            user: cutplaceData.user,
            renovation: cutplaceData.renovation,
        };
    }

    getData() {
        return this.data;
    }

    setData(data: ProfileData) {
        //console.log(this);
        this.data = {
            user: data.user,
            renovation: data.renovation,
        };
        this.cbs.forEach(cb => cb(data));
    }

    onDataChange(cb: (data: ProfileData) => any, callCb = true) {
        this.cbs.push(cb);
        if (callCb) {
            cb(this.data);
        }
    }
}

class ObjectUtil {
    hasOwn<K extends string>(o: Readonly<Partial<Record<K, unknown>>>, k: string): k is K {
        return Object.prototype.hasOwnProperty.call(o, k);
    }

    get<R extends any, D extends any>(o: Readonly<Record<string, R>>, k: string, defaultValue: D): R|D {
        if (this.hasOwn(o, k)) {
            return o[k];
        }
        return defaultValue;
    }
}

class ModalUtil {
    modal: CdsModal|null = null;
    onClose: ((modal: CdsModal) => any | Promise<any>)|null = null;

    openErrorModal(err: any) {
        this.openModal(errorUtil.getMessage(err), {header: 'Erreur'});
    }

    openModal(message: string|HTMLElement, options: {
        size?: 'sm'|'lg'|'xl'|number,
        header?: string|HTMLElement,
        actions?: string|HTMLElement,
        onClose?: ((modal: CdsModal) => void),
    } = {}) {
        this.closeModal();
        const modalAttrs = typeof options.size === 'number' ? {style: `--width:${options.size}px`} : {size: options.size || 'md'};
        const modal = nanohtml`<cds-modal ${modalAttrs}></cds-modal>` as CdsModal;
        if (options.header) {
            modal.append(nanohtml`<cds-modal-header cds-text="section" cds-first-focus="">${options.header}</cds-modal-header>`);
        }
        modal.append(nanohtml`<cds-modal-content cds-text="body">${message}</cds-modal-content>`);
        if (options.actions) {
            modal.append(nanohtml`<cds-modal-actions>${options.actions}</cds-modal-actions>`);
        }
        document.body.append(modal);
        modal.shadowRoot!.appendChild(nanohtml`<style>@media (min-width: 577px) {.modal-content{max-height: 92vh;}}</style>`);
        modal.addEventListener('closeChange', () => this.closeModal());
        this.modal = modal;
        this.onClose = options.onClose || null;
        return modal;
    }

    closeModal(modal?: CdsModal) {
        if (!this.modal || modal && modal !== this.modal) {
            return;
        }
        this.onClose?.(this.modal);
        this.modal.remove();
        this.modal = null;
    }
}

type AlertAction = {text: string, action: () => Promise<any>};
type AlertOptions = {type?: 'default'|'banner'|'light', status?: 'info'|'success'|'warning'|'danger', actions?: AlertAction[]};
class AlertUtil {
    createAlert(message: string|HTMLElement|DocumentFragment, options: AlertOptions = {}) {
        const {type, status, actions} = <const> {
            type: 'default',
            status: 'info',
            actions: [],
            ...options,
        };
        const alertElem = nanohtml`<cds-alert status="${status}">
            ${message || (status === 'danger' ? "Une erreur s'est produite" : '')}
            ${actions.length ? nanohtml`<cds-alert-actions>
                ${actions.map(action => {
                const onclick = async () => {
                    // status isn't really used atm since action removes alert elem
                    alertElem.status = 'loading';
                    button.disabled = true;
                    try {
                        await action.action();
                    }
                    catch (e) {
                        button.loadingState = 'error';
                    }
                    finally {
                        alertElem.status = status;
                        button.disabled = false;
                    }
                };
                const button = nanohtml`<cds-button onclick="${() => onclick()}" style="--action-font-size: 14px;">${action.text}</cds-button>` as CdsButton;
                return button;
            })}
            </cds-alert-actions>` : null}
        </cds-alert>` as CdsAlert;
        return nanohtml`<cds-alert-group type="${type}" status="${status}">${alertElem}</cds-alert-group>`;
    }

    createError(e: any, options: Omit<AlertOptions, 'status'> = {}) {
        return this.createAlert(errorUtil.getMessage(e), {...options, status: 'danger'});
    }
}

class NavigatorUtil {
    iOS() {
        return /(iPad|iPhone|iPod)/.test(navigator.platform) || navigator.userAgent.includes('Mac') && 'ontouchend' in document;
    }
    firefox() {
        return navigator.userAgent.toLowerCase().includes('firefox');
    }
}

class CaptchaUtil {
    private widgetId = new Map<HTMLFormElement, string>();
    private readonly ready: Promise<void>;

    constructor() {
        this.ready = new Promise((resolve) => {
            // setTimeout => reject
            turnstile.ready(resolve);
        });
    }

    async render(form: HTMLFormElement) {
        const elem = form.querySelector<HTMLElement>('.cf-turnstile');
        if (!elem) {
            return console.error('captcha elem not found');
        }
        if (this.widgetId.has(form)) {
            return console.error('captcha already active on this form');
        }
        // todo display loading + handle errors
        await this.ready;
        const widgetId = turnstile.render(elem, {
            sitekey: '0x4AAAAAAAOY-sytoh2i0wYT',
            theme: 'light',
            "expired-callback": () => {
                if (widgetId === this.widgetId.get(form)) {
                    this.reset(form);
                }
            },
        });
        if (widgetId === void 0) {
            return console.error('Error while rendering captcha');
        }
        this.widgetId.set(form, widgetId);
    }

    reset(form: HTMLFormElement) {
        const widgetId = this.widgetId.get(form);
        if (widgetId) {
            turnstile.reset(widgetId);
        }
    }

    remove(form: HTMLFormElement) {
        const widgetId = this.widgetId.get(form);
        if (widgetId) {
            (<any>turnstile).remove(widgetId);
        }
        this.widgetId.delete(form);
    }
}


function isHttpError<P = any>(e: any): e is FetchError<P, Response> {
    return e instanceof FetchError && e.response && e.err;
}

export class AppError extends Error {}

export class FetchError<P extends any = any, R extends Response|null = Response|null> extends AppError {
    constructor(public err: any, public response: R) {
        super(!response && isNetworkError(err) ? 'Veuillez vérifier votre connexion internet' : (typeof err?.message === 'string' ? err.message : "Une erreur s'est produite"));
    }

    getPayload(): P {
        return isHttpError(this) ? this.err.payload : void 0;
    }
}

class ErrorUtil {

    isHttpError<P>(e: any, ...codes: string[]): e is FetchError<P, Response> {
        return isHttpError(e) && (codes.length ? codes.includes(e.err.code) : true);
    }

    isValidationError(e: any): e is FetchError<{ path: (string|number)[], message: string, code: string }[], Response> {
        return this.isHttpError(e, 'CTP_VALIDATION_ERROR') && Array.isArray(e.err.payload);
    }

    isProfileError(e: any): e is FetchError<ProfileData, Response> {
        return this.isHttpError(e, 'CTP_EMAIL_VERIFIED', 'CTP_EMAIL_ACTIONS_DENY_VERIFY', 'CTP_RENOVATION_GUEST_UNAVAILABLE', 'CTP_RENOVATION_GUEST_LIMIT', 'CTP_RENOVATION_FREE_LIMIT');
    }

    getMessage(e: any) {
        return e instanceof AppError ? e.message : "Une erreur s'est produite";
    }
}

class FetchUtil {

    async fetchJSON<T = any>(url: string, postData: any = null, {abortTimeout, ...options}: RequestInit & {abortTimeout?: number, abortController?: AbortController, transformResponse?: (res: Response) => T|Promise<T>, tap?: (res: Response) => any|Promise<any>} = {}): Promise<T> {
        let controller: AbortController|undefined = options.abortController;
        let timeoutId: NodeJS.Timeout|null = null;
        let body;
        let headers: Headers = new Headers({
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });
        const isLocalUrl = !/^https?:\/\//.test(url);
        let csrfToken = this.getCsrfToken();
        if (csrfToken && isLocalUrl) {
            headers.set('x-csrf-token', csrfToken);
        }
        if (postData !== null) {
            body = postData instanceof FormData ? postData : JSON.stringify(postData);
            if (!(postData instanceof FormData)) {
                headers.set('Content-Type', 'application/json');
            }
        }
        if (options.headers) {
            new Headers(options.headers).forEach((value, key) => {
                headers.set(key, value);
            });
        }
        if (abortTimeout) {
            if (!controller) {
                controller = new AbortController();
            }
            timeoutId = setTimeout(() => controller!.abort(), abortTimeout);
        }
        if (controller) {
            options.signal = controller.signal;
        }
        options = {
            method: postData === null ? 'GET' : 'POST',
            body,
            ...options,
            headers,
            ...(isLocalUrl ? {credentials: 'include'} : {}),
        };
        let response: Response|null = null;
        try {
            response = await fetch(url, options);
            if (isLocalUrl && (csrfToken = response.headers.get('x-csrf-token'))) {
                this.setCsrfToken(csrfToken);
            }
            if (!response.ok) {
                throw await response.json();
            }
            else {
                await Promise.resolve(options.tap?.(response))
                return response.status === 204 ? null : (options.transformResponse ? options.transformResponse(response) : response.json());
            }
        }
        catch (err: any) {
            console.error(err);
            throw new FetchError(err, response);
        }
        finally {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }

    getCsrfMeta() {
        return document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]');
    }
    getCsrfToken() {
        return this.getCsrfMeta()?.content || null;
    }
    setCsrfToken(token: string) {
        const csrfMeta = this.getCsrfMeta();
        if (csrfMeta) {
            csrfMeta.content = token;
        }
    }

}

type FormErrors = { root: Set<string>, children: Map<string|number, Set<string>> };
class FormUtil {

    private rootError = new WeakMap<HTMLFormElement, HTMLElement>();
    private controls = new WeakMap<HTMLFormElement, Map<string|number, HTMLElement>>();
    private errors = new WeakMap<HTMLFormElement, FormErrors>();

    initForm<T extends any = any>(form: HTMLFormElement, opts: {
        beforeSubmit?: (data: FormData) => any|Promise<any>,
        onSuccess?: (data: T) => any|Promise<any>,
        onError?: (err: any) => any|Promise<any>,
    } = {}) {
        let submitElem = form.querySelector<HTMLInputElement>('input[type="submit"]');
        if (!submitElem) {
            submitElem = document.createElement('input');
            submitElem.type = 'submit';
            submitElem.classList.add('hidden');
            form.append(submitElem);
        }
        let rootError = form.querySelector<HTMLElement>('.form-error');
        if (!rootError) {
            rootError = document.createElement('div');
            rootError.classList.add('form-error');
            rootError.setAttribute('cds-layout', 'align:center');
            const submit = form.querySelector('cds-button[type=submit]');
            if (submit) {
                submit.before(rootError);
            }
            else {
                form.append(rootError);
            }
        }
        rootError.classList.add('hidden');
        rootError.innerHTML = '';
        this.rootError.set(form, rootError);
        const controls = new Map<string, HTMLElement>();
        this.controls.set(form, controls);
        for (const elem of Array.from(form.elements) as HTMLElement[]) {
            const name = elem.getAttribute('name');
            if (!name) {
                continue;
            }
            // 'cds-control-group' not used right now, but kept so that it works if we add them
            let control = this.findControl(form, elem, 'cds-control-group') || this.findControl(form, elem, 'cds-control');
            if (control) {
                controls.set(name, control);
            }
        }
        form.addEventListener('submit', async (ev) => {
            ev.preventDefault();
            let redirected = false;
            const fd = new FormData(form);
            try {
                const postData = await opts.beforeSubmit?.(fd) || fd;
                this.resetErrors(form);
                this.setLoading(form, true);
                const data = await fetchUtil.fetchJSON<T>(form.getAttribute('action')!, postData);
                const redirect = form.dataset.redirect;
                if (redirect) {
                    window.location.href = redirect;
                    redirected = true;
                }
                else {
                    await opts.onSuccess?.(data);
                }
            }
            catch (err) {
                this.displayErrors(form, err);
                await opts.onError?.(err);
            }
            finally {
                if (!redirected) {
                    this.setLoading(form, false);
                }
            }
        });
    }

    setLoading(form: HTMLFormElement, loading: boolean) {
        for (const elem of Array.from(form.elements)) {
            (<any>elem).disabled = loading;
        }
        form.querySelectorAll<CdsButton>('cds-button[type=submit]').forEach(button => button.disabled = loading);
    }

    displayErrors(form: HTMLFormElement, err: any) {
        this.resetErrors(form);
        if (errorUtil.isValidationError(err)) {
            const formErrors: FormErrors = {
                root: new Set(),
                children: new Map(),
            }
            this.errors.set(form, formErrors);
            for (const error of err.getPayload()) {
                let message = error.message;
                const path = error.path[0];
                const control = this.controls.get(form)?.get(path);
                if (typeof error.message !== 'string' || !message) {
                    message = control ? 'Cette valeur est invalide' : 'Le formulaire est invalide';
                }
                if (!control) {
                    formErrors.root.add(message);
                }
                else {
                    let messages = formErrors.children.get(path!);
                    if (!messages) {
                        messages = new Set();
                        formErrors.children.set(path!, messages);
                    }
                    messages.add(message);
                }
            }
            const rootMessages = formErrors.root;
            if (rootMessages.size) {
                this.setRootError(form, Array.from(rootMessages.values()).join("\n"));
            }
            for (const [path, messages] of formErrors.children) {
                const control = this.controls.get(form)!.get(path)!;
                control.append(nanohtml`<cds-control-message status="error">${Array.from(messages.values()).join("\n")}</cds-control-message>`); // todo newline not working
            }
        }
        else {
            this.setRootError(form, err);
        }
    }

    resetErrors(form: HTMLFormElement) {
        const rootError = this.rootError.get(form);
        if (rootError) {
            rootError.classList.add('hidden');
            rootError.innerHTML = '';
        }
        for (const [path, messages] of this.errors.get(form)?.children || []) {
            this.controls.get(form)?.get(path)?.querySelectorAll<HTMLElement>('cds-control-message[status="error"]')?.forEach(item => item.remove());
        }
        this.errors.delete(form);
    }

    clearForm(form: HTMLFormElement) {
        this.controls.delete(form);
        this.errors.delete(form);
    }

    private setRootError(form: HTMLFormElement, err: any) {
        const rootError = this.rootError.get(form);
        if (rootError) {
            rootError.append(alertUtil.createError(err));
            rootError.classList.remove('hidden');
        }
    }

    private findControl(form: HTMLFormElement, elem: HTMLElement, controlType: string) {
        const controlConstructor = customElements.get(controlType);
        if (!controlConstructor) {
            console.error(`Missing ${controlType} custom element`);
            return null;
        }
        let el: HTMLElement|null = elem;
        while ((el = el.parentElement)) {
            if (el === form) {
                break;
            }
            if (el instanceof controlConstructor) {
                return el;
            }
        }
        return null;
    }
}

class UploadUtil {
    protected places = places;
    protected styles = styles;
    protected placesType = placesType;

    initFileInput(input: HTMLInputElement, drop: HTMLElement, onFiles: (files: File[], hasHeif: boolean) => any, onError: (error: AppError) => any) {
        const multiple = input.multiple;
        const zoneElem = nanohtml`<div id="dnd-zone" class="hidden" style="
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10000;
          background: rgba(0, 0, 0, .3);
        "></div>`;
        drop.append(zoneElem);
        drop.addEventListener('dragover', (ev) => {
            ev.preventDefault();
        });
        drop.addEventListener('dragenter', () => {
            zoneElem.classList.remove('hidden');
        });
        zoneElem.addEventListener('dragleave', () => {
            zoneElem.classList.add('hidden');
        });
        drop.addEventListener('dragover', (ev) => {
            ev.preventDefault();
        });
        zoneElem.addEventListener('drop', (ev) => {
            ev.preventDefault();
            if (!ev.dataTransfer) {
                return;
            }
            const files = [];
            if (ev.dataTransfer.items) {
                for (const item of Array.from(ev.dataTransfer.items)) {
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        if (file) {
                            files.push(file);
                        }
                    }
                }
            }
            else {
                for (const f of Array.from(ev.dataTransfer.files)) {
                    files.push(f);
                }
            }
            this.validateFiles(files, multiple, onFiles, onError);
            zoneElem.classList.add('hidden');
        });
        input.addEventListener('input', () => {
            const files = [];
            for (let i = 0; i < input.files?.length! || 0; ++i) {
                files.push(input.files![i]);
            }
            this.validateFiles(files, multiple, onFiles, onError);
        });
    }

    initSelects(placeSelect: HTMLSelectElement, styleSelect: HTMLSelectElement, {place, style, onPlaceInput, onStyleInput}: {
        place?: string,
        style?: string,
        onPlaceInput?: (type: string) => void,
        onStyleInput?: Function
    } = {}) {
        placeSelect.required = true;
        placeSelect.name = 'place';
        styleSelect.name = 'style';
        placeSelect.append(new Option('Photo de...', ''));
        styleSelect.append(new Option('Style aléatoire', ''));
        for (const [value, text] of Object.entries(this.places)) {
            placeSelect.append(nanohtml`<option value="${value}" data-type="${this.placesType[value as keyof UploadUtil['placesType']]}">${text}</option>`);
        }
        const styleOptions: HTMLOptionElement[] = [];
        for (const [type, stylesType] of Object.entries(this.styles)) {
            for (const [value, text] of Object.entries(stylesType)) {
                styleOptions.push(nanohtml`<option value="${value}" data-type="${type}">${text}</option>` as HTMLOptionElement);
            }
        }
        placeSelect.addEventListener('input', () => {
            const type = placeSelect.selectedOptions[0].dataset.type!;
            for (const option of styleOptions) {
                const display = !option.value || option.dataset.type === type;
                if (display) {
                    styleSelect.append(option);
                }
                else {
                    if (option.selected) {
                        styleSelect.value = '';
                    }
                    option.remove();
                }
            }
            onPlaceInput?.(type);
        });
        if (onStyleInput) {
            styleSelect.addEventListener('input', () => onStyleInput());
        }
        if (place && Array.from(placeSelect.options).find(o => o.value === place)) {
            placeSelect.value = place;
            placeSelect.dispatchEvent(new Event('input'));
        }
        if (style && Array.from(styleSelect.options).find(o => o.value === style)) {
            styleSelect.value = style;
        }
    }

    private validateFiles(files: File[], multiple: boolean, onSuccess: (files: File[], hasHeif: boolean) => any, onError: (error: AppError) => any) {
        let error: string|undefined;
        let hasHeif = false;
        if (!multiple && files.length > 1) {
            error = "Veuillez ne charger qu'un seul fichier à la fois";
        }
        else {
            for (const file of files) {
                if (file.size >= maxFileSize) {
                    error = `Veuillez charger des fichiers de moins de ${maxFileSize/1000000}Mo chacun`;
                    break;
                }
                if (file.type.indexOf('image/') !== 0) {
                    error = 'Veuillez ne charger que des fichiers image valides';
                    break;
                }
                if (file.type.indexOf('image/hei') === 0) {
                    hasHeif = true;
                }
            }
        }
        if (error) {
            onError(new AppError(error));
        }
        else {
            onSuccess(files, hasHeif);
        }
    }
}

class ShareUtil {

    openModal(url: string) {
        const copy = () => {
            copyButton.loadingState = 'loading';
            this.copy(url).then(() => copyButton.loadingState = 'success').catch(() => copyButton.loadingState = 'error');
        };
        const copyButton = nanohtml`<cds-button onclick="${() => copy()}" action="outline"><cds-icon shape="copy"></cds-icon> Copier</cds-button>` as CdsButton;
        modalUtil.openModal(nanohtml`<div cds-layout="vertical gap:md">
            <div cds-text="body">CutPlace vous propose une visionneuse que vous pouvez intégrer à votre site ou partager librement à travers le lien suivant :</div>
            <div cds-layout="horizontal align:center" cds-text="message"><a cds-text="link" href="${url}" target="_blank" style="word-break: break-all;">${url}</a></div>
        </div>`, {
            header: 'Lien de partage',
            actions: nanohtml`<div cds-layout="horizontal align:center">${copyButton}</div>`,
        });
    }

    copy(text: string) {
        if (navigator.clipboard) {
            return navigator.clipboard.writeText(text).catch(() => this.copyFallback(text));
        }
        return this.copyFallback(text);
    }

    private async copyFallback(text: string) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.zIndex = "-1";
        textarea.style.top = "0";
        textarea.style.left = "0";
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            if (!document.execCommand('copy')) {
                throw new AppError("Votre navigateur ne supporte pas la copie automatique, veuillez sélectionner et copier le texte manuellement");
            }
        }
        finally {
            document.body.removeChild(textarea);
        }
    }

}

export const objectUtil = new ObjectUtil();
export const profileUtil = new ProfileUtil();
export const modalUtil = new ModalUtil();
export const alertUtil = new AlertUtil();
export const navigatorUtil = new NavigatorUtil();
export const captchaUtil = new CaptchaUtil();
export const fetchUtil = new FetchUtil();
export const formUtil = new FormUtil();
export const errorUtil = new ErrorUtil();
export const uploadUtil = new UploadUtil();
export const shareUtil = new ShareUtil();
