
export const maxFileSize = 8000000;
export const maxRenovationsPerWeek = 100;
export const maxRenovationsPerWeekAdmin = 1000;
export const maxRenovationsByFolder = 20;

export const places = <const> {
    living_room: 'Salon',
    kitchen: 'Cuisine',
    dining_room: 'Salle à manger',
    bedroom: 'Chambre',
    bathroom: 'Salle de bains',
    study: 'Bureau',
    empty_room: 'Pièce vide',
    laundry_room: 'Buanderie',
    water_closet: 'WC',
    staircase: "Cage d'escalier",
    garage: 'Garage',
    attic: 'Grenier',
    basement: 'Sous-sol',
    facade: 'Façade',
    terrace: 'Terrasse',
    courtyard: 'Cour',
};
export const styles = {
    interior: {
        industrial: 'Industriel',
        scandinavian: 'Scandinave',
        brutalist: 'Béton brut',
        tropical: 'Tropical',
        modern: 'Moderne',
        provence: 'Provence',
        shabby: 'Shabby chic',
        medieval: 'Médiéval',
        chalet: 'Chalet bois',
        seaside: "Bord de mer",
    },
    facade: {
        plaster: 'Enduit',
    },
    exterior: {
        tiles: 'Carrelage',
    },
};
export const placesType: Record<keyof typeof places, keyof typeof styles> = {
    living_room: 'interior',
    kitchen: 'interior',
    dining_room: 'interior',
    bedroom: 'interior',
    bathroom: 'interior',
    study: 'interior',
    empty_room: 'interior',
    laundry_room: 'interior',
    water_closet: 'interior',
    staircase: 'interior',
    garage: 'interior',
    attic: 'interior',
    basement: 'interior',
    facade: 'facade',
    terrace: 'exterior',
    courtyard: 'exterior',
};